//
// Sidebar
// --------------------------------------------------

.sidebar{
  ul,li{
    margin:0;
    padding:0;
    list-style-type: none;
  }
  h3{
    @extend .beta;
  }
  .widget_categories{
    li a{
      @include rem(margin-bottom, 10);
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 700;
    }
  }
}