.reach-out.content-block{
  color:#FFF;
  background-color: palette(brand,accent);
  @include background-noise('left','white');
  .icon-stroke{
    fill:palette(brand,accent);
    background-color: #fff;
  }
  &::after{
    opacity: .8;
    background-size: inherit;
    background-position: center left;
  }
  @include add-padding-no-desk;
  h2.header{
    
    @include from(pt){
      font-size: rem-calc(45);
    }
  }
  .inner{
    max-width: rem-calc(800);
    margin-left: auto;
    margin-right: auto;
    @include from(pt){
      display: flex;
      justify-content: space-between;
    }
  }
  @include from(pt){
    .coffee{
      background:url("../images/coffee.png") no-repeat 0 0;
      background-size: contain;
      flex:0 1 40%;
      background-position: center;
    }
    .content{
      flex:0 1 55%;
      padding-bottom: 40px;
    }
  }

}
.background-color-dark + .reach-out.content-block{
  .icon-stroke{
    background-color: palette(grey,page);
  }
}