//
// Hero
// --------------------------------------------------
//
$home-hero-height: 100vh;
//


.hero {
  position: relative;
  overflow: hidden;
  color: palette(white);
  background-color: palette('brand','accent');

  .wrap {
    position: relative;
    z-index: 1;
    margin-left: 0;
    margin-right:0;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  h1{
    line-height: 1.1;
    
  }
  
  .contact-blocks{
    position: absolute;
    left:0;
    bottom:0;
  }
  &.home-hero {
    // option
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    min-height: 375px;
    @if $nav-in-hero == true {
      height: $home-hero-height;

      @include from(sd) {
        height: $home-hero-height;
      }
    } @else {
      height: calc(#{$home-hero-height} - #{rem-calc($header-height-mobile)});

      @include from(sd) {
        height: calc(#{$home-hero-height} - #{rem-calc($header-height-default)});
      }
    }
    .wrap{
      h1{
        font-size: rem-calc(30);
        margin-bottom: rem-calc(20);
        @include from(pt){
          font-size: rem-calc(50);
        }
      }  
      h3{
        font-size: rem-calc(20);
        margin-top: rem-calc(60);
        margin-bottom: 0;
        text-transform: uppercase;
        @include from(pt){
          font-size: rem-calc(25);
        }
      }
    }
  }
  &.back {
    @include background-noise('left','white');
    .wrap {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      @include rem(min-height, 200);

      @include from(pt) {
  
        @include rem(min-height, 260);
      }

    }
  }
}