//
// Gallery
// --------------------------------------------------

.content-block.gallery {

  .inner {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  a{
    display: block;
  }
  img {
    width: 100.5%;
    max-width: none;
    display: block;
    transition: all .4s ease;
    will-change:transform;
  }

  a.gallery {
    outline: 0;
    display: block;
    flex:0 1 50%;
    overflow: hidden;

    &.hide {
      position: absolute;left:-9999em;
    }

    @include from(lp) {
      flex:0 1 25%;
    }

    &:hover {

      img {
        transform:scale(1.01,1.01);
      }
    }
  }

  .last {
    position: relative;
    color:#FFF;
    @include rem(font-size, 48);

    span {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      left:0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::after {
      z-index: 1;
      background-color: rgba(#000, .7);
      content: "";
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
    }
  }
}


// mfp

.mfp-fade.mfp-wrap.mfp-ready {

  &.next {

    .mfp-content {
      opacity: 0;
      transition: all .4s $ease-out-cubic;
      transform: scale(1) translateX(-20%);
    }

    &.mfp-image-loading {

      .mfp-content {
        transform: scale(1) translateX(20%);
        transition: all 0s linear;
      }
    }
  }

  &.prev {

    .mfp-content {
      opacity: 0;
      transition: all .4s $ease-out-cubic;
      transform: scale(1) translateX(20%);
    }

    &.mfp-image-loading {

      .mfp-content {
        transform: scale(1) translateX(-20%);
        transition: all 0s linear;
      }
    }
  }
}