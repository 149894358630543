//
// Loader Overlay
// --------------------------------------------------

.overlay-show {
  @include overlay(0, palette(black), fixed, none);
  transition: opacity .4s $ease-out-cubic;
  z-index: 1000;

  .overlay-visible &,
  &.show {
    pointer-events: all;
    opacity: .6;
  }
}


//
// Loader
// --------------------------------------------------

.loader {
  @include translateX(101%);
  background-color: palette(white);
  //border-left: 1px solid $border-color;
  height: 100%;
  max-width:rem-calc(580);
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll; /* has to be scroll, not auto */
  position: fixed;
  right: 0;
  top: 0;
  transition: transform .4s $ease-out-cubic;
  width: 100%;
  will-change: transform;
  z-index: 1300;

  &.show {
    @include translateX(0%);
  }

  h2,
  h3 {
    color: palette(brand);
  }

  h2,
  h3 {
    font-size: rem-calc(30);
  }

  > .content {
    padding: rem-calc(40 20);

    @include from(lt) {
      padding: rem-calc(60 50 40 50);
    }
  }

  .close {
    position: absolute;
    top: rem-calc(20);
    right:rem-calc(20);
    color: palette(white);
    font-weight: 700;
    text-transform: uppercase;
    font-size: rem-calc(14);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .no-csstransforms & {
    right: -200%;

    &.show {
      right: 0%;
    }
  }

  .img-wrap {
    @include rem(height, 300);
    @include rem(width, 300);
    padding-bottom: 0%;
    margin-left: auto;
    margin-right: auto;

    .bg-img { }
  }

  .header {
    margin-top: rem-calc(30);
    margin-bottom: rem-calc(40);

    .title,
    .sub-title {
      margin-bottom: rem-calc(5);
    }
  }
}

.visible-off-canvas {
  display: none;

  .loader & {
    display: block;
  }
}


// Team Loader

.loader-team {
  .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before{
    background-color: palette(brand);
  }
  .close {
    color: palette(white);
  }

  > .content {
    padding: 0;

    .header,
    .content {
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);

      @include from(lt) {
        padding-left: rem-calc(50);
        padding-right: rem-calc(50);
      }
    }
  }
}