// set svg d path used as fallback (star)
$svg-d-path: 'm25,1l6,17l18,0l-14,11l5,17l-15,-10l-15,10l5,-17l-14,-11l18,0l6,-17z' !default;

// functions to urlencode the svg string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@function url-encode($string) {
  $map: (
   "%": "%25",
   "<": "%3C",
   ">": "%3E",
   " ": "%20",
   "!": "%21",
   "*": "%2A",
   "'": "%27",
   '"': "%22",
   "(": "%28",
   ")": "%29",
   ";": "%3B",
   ":": "%3A",
   "@": "%40",
   "&": "%26",
   "=": "%3D",
   "+": "%2B",
   "$": "%24",
   ",": "%2C",
   "/": "%2F",
   "?": "%3F",
   "#": "%23",
   "[": "%5B",
   "]": "%5D"
   );
  $new: $string;
  @each $search, $replace in $map {
    $new: str-replace($new, $search, $replace);
  }
  @return $new;
}

@function inline-svg($string) {
  @return url('data:image/svg+xml;charset=UTF-8,#{url-encode($string)}');
}