//
// Accordion
// --------------------------------------------------

.content-block.accordion {
  width: 100%;

  ul {
    list-style: none;
  }

  li {
    list-style: none;

    &:first-child .accordion-container {
      border-top: 1px solid palette(grey, light);
    }
  }

  .accordion-handler {
    border-bottom: 1px solid palette(grey, light);
  }

  .accordion-container {
    border-top: 1px solid palette(grey, light);
    @include rem(padding, 0, 0, 0);

    &:first-child {
        border-top: 1px solid palette(grey, light);
    }

    h3 {
      position: relative;

      strong {
        width: 143px;

        @include from(pt) {
          display:inline-block;
          width: 143px;
        }
      }

      &:before {
        @extend %icon;
        @extend %down;
        position: absolute;
        right: 10px;
        width: 21px;
        height: 21px;
        @include transition(transform .3s linear);
      }

      &.active-header{
        &:before{
          @include transform(rotateZ(180deg));
        }
      }
    }

    .accordion-header {
      @extend %list-link-style;
      @include rem(padding, 15, 30,15,0);
      position: relative;
      cursor: pointer;
      margin: 0;
      font-family: $body-font;
      text-transform: none;
      font-weight: 400;
      line-height: 1.5;
      font-size: rem-calc(17);

      .icon {
        height: 13px;
        width: 14px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        transition: transform .3s $ease-in-out-quad;
        fill: #82888a;
      }

      &:before {
        @include icon();
        @extend %plus;
        color:palette(brand);
        position: absolute;
        right: 0px;
        top: 50%;
        @include rem(font-size, 24);
        @include rem(margin-top, -10);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition:all .3s linear;
        @include transform(rotate(0deg));
        height: auto;
        width: auto;
        line-height: 1em;
        display:block;
      }

      &.active-header {

        &:before {
          @include transform(rotate(135deg) translate3d(-2px, 4px, 0));
        }
      }

      em {
        position: absolute;
        @include rem(top, 34);
        @include rem(font-size, 16);
      }
    }

    .accordion-content {
      display: none;
      position: relative;
      overflow: hidden;
      @include rem(padding, 20,10,40);
      text-align: left;
      will-change:transform;
      @include transition(transform .3s ease);
      @include transform(scale(.9, .9));

      @include from(lp) {
        @include rem(padding, 20,30,40);
      }

      ul {
        margin: 5px 0 5px;
        @include rem(margin-bottom, 20);
        padding: 0;
        column-count: 2;

        li {
          @include to(pp) {
            font-size: rem-calc(16);
          }
        }

        @include from(pt) {
          column-count: 3;
        }
      }

      p {
        margin-top: 0 !important;
      }

      &.open-content {
        @include transform(scale(1, 1));
      }
    }
  }
}

