//
// Team Members
// --------------------------------------------------

.team-members.content-block {
  padding: 0;

  .page-content {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .inner {

    // @include from(lp) {
      display: flex;
      flex-flow: row wrap;
    // }
  }
}

.team-member {
  overflow: hidden;
  text-align: center;
  margin-bottom: rem-calc(20);
  flex: 0 0 percentage(1/2);

  .large & {
    flex: 0 0 100%;
  }

  @include from(lp) {
    flex: 0 0 percentage(1/3);
  }

  @include from(lt) {
    flex: 0 0 percentage(1/5);

    .large & {
      flex: 0 0 50%;
    }
  }

  .title,
  .sub-title {
    margin-bottom: rem-calc(5);
  }
  .title{
    font-size: rem-calc(20);
    font-weight: 400;
  }
  .sub-title{
    font-size: rem-calc(16);
    text-transform: uppercase;
    font-family: $special-font;
    letter-spacing: 1px;
    font-weight: 600;
  }
  .content {
    @extend .visible-off-canvas;
  }
}

.member-inner {
  display: block;
  color:$text-color;
  &:hover{
    color:palette(brand);
  }
}

.member-image {
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: rem-calc(20);
  .bg-img{
    will-change:transform;
    transition: all .8s $ease-out-circ;
  }
  &:hover{
    .bg-img{
      transform:scale(1.05);
    }
  }
}
