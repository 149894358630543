@mixin pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin clearfix {
  overflow: hidden;
}

@mixin squish-text {
  font: 0/0 serif;
  text-shadow: none;
  color: transparent;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin opacity($opacity){
  opacity:$opacity;
}

@mixin translateX($t){
  @include transform(translateX($t));
}

@mixin translateY($t){
  @include transform(translateY($t));
}

@mixin translateZ($t){
  @include transform(translateZ($t));
}
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null){
     object-fit: $fit;
  @if $position {
	     object-position: $position;
  	font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
  	font-family: 'object-fit: #{$fit}';
  }
}
