//
// Transitions
// --------------------------------------------------

%default-transition,
%dft{
	@include transition-property(background-color, color, border-color);
	@include transition-duration(0.25s);
	@include transition-timing-function(linear);
}
%trans-all{
	@include transition-property(all);
	@include transition-duration(0.75s);
	@include transition-timing-function(linear);
}

.lazyload{

}

.fade-lazyload{
	@include transition-property(opacity);
	@include transition-duration(0.25s);
	@include transition-timing-function(easeOutCubic);
	@include opacity(0);
	&.lazyloaded{
		@include opacity(1);
	}
}
