.contact-blocks{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  svg.icon{
    fill:#FFF;
    width: 44px;
    height: 44px;
    transition: transform .9s $ease-out-circ, opacity .5s $ease-out-circ;
    transform:translateY(20%);
    opacity: 0;
    will-change:transform;
    &.icon-cell{
      width: 27px;
    }
    .pace-done &{
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  h3,
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  h3{
    margin-bottom: 0;
    font-family: $special-font;
    font-weight: 900;
    font-size:rem-calc(18);
    line-height: 1;
    text-align: left;
    text-transform: uppercase;
    display: none;
    @include from(lp){
      display: flex;
      padding-left: rem-calc(10);
    }
    @include from(pt){
      font-size:rem-calc(20);
      flex:1;
      justify-content: flex-start;
    }
    @include from(lt){
      font-size:rem-calc(25);
      padding-left: rem-calc(15);
    }
  }

  span.icon{
    $opacity-1:0.08;
    $opacity-2:0.13;
    @include from(lp){
      flex:0 1 27%;
    }
    @include from(pt){
      flex:0 1 35%;
      background: linear-gradient(135deg, rgba(0,0,0,$opacity-1) 0%,rgba(0,0,0,$opacity-1) 50%,rgba(10,14,10,$opacity-2) 51%,rgba(10,8,9,$opacity-2) 100%);
    }
    @include from(lt){
      flex:0 1 37%;
    }
    @include from(sd){
      flex:0 1 29%;
    }
  }

  .inner{
    max-width: rem-calc(1200);
    width: 100%;
    display: flex;
  }
  .contact--block{
    color:#FFF;
    background-color: palette(brand);
    flex:1;
    margin-right: 2px;
    @include background-noise($color:'black');
    &::after{
      background-position: center bottom;
      background-size: inherit;
      opacity: .8;
    }
    &:last-child{
      margin-right: 0;
    }
    &:hover{
      svg{
        transition: transform 4s $ease-out-cubic;
        transform: translateY(-20%);
        @include from(pt){
          transform: translateY(-30%);
        }
      }
    }
    &__inner{
      display: flex;
      align-items: stretch;
      justify-content: center;
      height: rem-calc(90);
      @include from(lp){
        justify-content: flex-start;
      }
      @include from(pt){
        
      }
      @include from(lt){
        height: rem-calc(120);
      }
    }

  }
}