//
// Contact page
// --------------------------------------------------

.contact-columns {
  @include content-vertical-spacing;
  overflow: hidden;
  max-width: rem-calc(1000);
  line-height: 1.3;
  .contact-col {
    margin-bottom: rem-calc(45);
    @include from(pt) {
      @include grid-span(1, 1, $grid: 1 2, $gutter: .3);
      margin-bottom: 0;
      text-align: left;

      &:last-child {
        @include grid-span(1, 2, $grid: 1 2, $gutter: .3);
      }
    }
  }
}

.contact-form {

  .gform_body {
    margin-top: 0;
  }

  #input_1_1 {
    margin-top: 0;
  }
}
.phone-numbers{
  margin:rem-calc(15 0);
  .phone-num{
    display: block;
  }
}
.contact-area {
  font-size: 15px;
  svg {
    @include rem(width, 20);
    @include rem(height, 20);
    @include rem(margin-right, 10);
    fill: palette(brand);
  }

  h4 {
    @include rem(font-size, 20);
    margin-bottom: 0;
    font-weight: 400;
    margin-bottom: rem-calc(15);
  }
  address{
    font-style: normal;
  }
  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    // align-items: center;
    // color: palette(brand);
    // display: flex;
    // font-weight: $font-weight-regular;
    // justify-content: flex-start;
  }

  li {
    @include rem(padding, 10, 0);
    line-height: 1.2;

    &:first-child {
      padding-top: 0;
    }
  }

  .hours {
    li {
      padding: 0;
    }
  }
}
.contact.addresses{
  .address{
    margin-bottom: rem-calc(20);
  }
}

.contact-info-mobile {
  display: flex;
  position: fixed;
  bottom:0;
  width:100%;
  left:0;
  z-index: 100;
  background-color: palette(brand);

  .header-float & {
    position: fixed;
    transform: translateY(100%);
    border-bottom: 1px solid palette(grey, light);
    transition: transform 0.4s $ease-out-cubic;

    &.header-up,
    &.header-down {
      transition: transform 0.4s $ease-out-cubic;
    }

    &.header-up {
      transform: translateY(100%);
    }

    &.header-down {
      transform: translateY(0%);
    }
  }

  @include from(lp){
    z-index: 1;
    position: relative;
  }

  @include from(pt) {
    display: none;
  }

  a {
    color: palette(white);
    width: percentage(1 / 3);
    text-align: center;
    font-size: rem-calc(13);
    text-transform: uppercase;
    letter-spacing: rem-calc(1);
    border-bottom: 1px solid palette(white, brand);

    .big {
      display: none;
    }

    .small {
      display: inline-block;
    }

    .content {
      height: 100%;
      padding-bottom: rem-calc(12);

      @include from(lt) {
        padding: rem-calc(20 10 30);

        .big {
          display: inline-block;
        }

        .small {
          display: none;
        }
      }
    }

    &:not(:last-child) {
      border-right: 1px solid palette(white, brand);
    }

    .icon {
      display: block;
      height: rem-calc(30);
      line-height: 1;
      width: rem-calc(30);
      margin: 0 auto rem-calc(10);
      fill: white;
      @include from(lp){
        width: rem-calc(30);
        height: rem-calc(30);
      }
      @include from(pt){
        width: rem-calc(40);
        height: rem-calc(40);
      }
    }
  }
}

a.effect {
  overflow: hidden;
  position: relative;

  .content {
    display: block;
    background: palette(brand);
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: palette(brand, two);
    color: palette(white);
    content: attr(data-hover);
    transform: translateX(-25%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content,
  &:before {
    padding: rem-calc(15);
    transition: transform 0.9s $ease-in-out-back 0.1s;
    @include from(lp){
      padding: rem-calc(20);
    }
    @include from(pt){
      padding: rem-calc(30);
    }
  }

  @include from(sd) {
    &:hover,
    &:focus {

      .content {
        transform: translateX(100%);
      }

      &:before {
        transform: translateX(0%);
      }
    }
  }
}