//
// Tiles
// --------------------------------------------------

.content-block.two-tile {
  overflow: hidden;
  @include from(pt){
    &.background-color-light{
      .content{
        background: url("../images/grunge-top.jpg") no-repeat center top;
        background-size: contain;
      }
    }
  }
  &:last-child{
    @include to(pt){
      padding-bottom: rem-calc(40);
    } 
  }
  @include from(lt) {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .add-padding {
    overflow: hidden;
    //@include content-vertical-padding;
  }

  header {
    @include rem(margin-bottom, 15);

    h2,
    h3 {
      margin-bottom: 0;
    }
    h2{
      font-weight: 400;
      margin-bottom: rem-calc(20);
      font-size: rem-calc(26);

    }
    h3 {
      font-size: rem-calc(32);
    }
  }

  figure {
    margin-bottom: 0;
    text-align: center;
    position: relative;
    overflow: hidden;
    height: 80vw;
    @include from(lt){
      height: auto;
      @include rem(margin-bottom, 25);
    }
    img { }
  }
  .link-holder{
    margin-top: rem-calc(30);
  }
  .content {
    
    .inner {
      margin-left: auto;
      margin-right: auto;
      @include content-vertical-spacing();
      @include add-padding-no-desk;
      max-width: rem-calc($max-content-width);
      @include to(lp){
        padding-bottom: 0;
      }
      @include from(lt) {
        @include rem(max-width, 473);
 
      }

      @include from(d) {
    
      }
      @include from(ld){
        padding-top: rem-calc(200);
        padding-bottom: rem-calc(200);
      }
    }

    @include from(lt) {
      display:flex;
      justify-content:center;
    }
  }

  .button { }

  @include from(lt) {

    figure {
      padding-bottom: 0;
      margin-bottom: 0;
      display:flex;
      align-items:center;

      img,
      picture {
        width: 100%;
        display: block;
      }
    }

    .content {
      display:flex;
      align-items:center;
      justify-content:center;

      .inner {
        margin-left: 0;
        margin-right: 0;
      }
    }

    figure,
    .content {
      width: 50%;
    }

    &.left {

      figure {
        order: 2;
      }
    }
  }
}