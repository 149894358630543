%list-container {
  overflow: hidden;
  @include rem(padding,10,0);
}

.page-content,
.constrained {
  @include rem(max-width,$max-content-width);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
}

.page-content {
  @include add-padding-no-desk;
  @include pie-clearfix;
  &.medium {
    @include rem(max-width,880);
  }

  &.wide {
    @include rem(max-width,980);
  }

  &.full-width {
    max-width: none;
    @include add-padding;
  }

  .link-handler {
    @include rem(margin-top,40);
    text-align:left;

    .btn:not(:first-child) {
      margin-left: rem-calc(15);
    }
  }
}

.content-block {
  @include content-vertical-spacing;
  position: relative;
  overflow: hidden;

  &.remove-padding {
    @include remove-content-vertical-spacing-top;
  }

  &.background-color-light.padding-collapse + .background-color-light.padding-collapse,
  &.background-color-dark.padding-collapse + .background-color-dark.padding-collapse {
    @include remove-content-vertical-spacing-top;
  }

  &.two-tile,
  &.slider,
  &.image-grid,
  &.gallery,
  &.content-google-map {
    padding-top: 0;
    padding-bottom: 0;
  }

}

.main,
.sidebar {

  .home &,
  .page & {
    margin-top: 0;
    margin-bottom: 0;
  }

}


section {

  &.background-color-dark,
  &.dark {
    background-color:palette(grey,page);
  }

  .page-content {
    position: relative;
  }

}