//
// Standard Text
// --------------------------------------------------

.standard-text.content-block{
  .icon-stroke{
    fill:palette(grey,page);
    background-color: #fff;
  }
  .home &{
    text-align: center;
  }
  &.background-color-light{
    .icon-stroke{
      display: none;
    }
  }
  @include from(pt){
    &.background-color-light{
      background: url("../images/grunge-top.jpg") no-repeat center top;
      background-size: contain;
    }
  }
  h2.header,
  h3.subheader{
     text-align: center;
  }
  h2.header{
    font-family: $special-font;
    text-transform: uppercase;
    font-weight: 600;
    color:#000;
    font-size: rem-calc(16);
    letter-spacing: 2px;
  }
  .line-holder{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem-calc(30);
  }
  h3.subheader{
    @include from(pt){
      font-size: rem-calc(45);
    }
  }
}
.standard-content{
  //@include default-list;
}