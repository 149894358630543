//
// Tables
// --------------------------------------------------
.table.content-block{
  .page-content{
    max-width: rem-calc(900);
  }
}
.layout-table{
  width: 100%;
  font-size: rem-calc(12);
  th,td{
    padding:rem-calc(10 20);
    &:first-child{
      @include to(pp){
        background-color: #303134;
        color:#FFF;
      }
    }
    @include from(lp){
      padding:rem-calc(20 10);
      text-align: center;
      &:first-child{
        text-align: left;
      }
    }
  }
  thead{
    background-color: #303134;
    color:#FFF;
    th{
      font-weight: 400;
      text-transform: uppercase;
      display: none;
      @include from(lp){
        display: table-cell;
      }
    }
  }
  tbody{
    td{
      font-size: rem-calc(14);
      &:first-child{
        font-weight: 700;
      }
      @include to(pp){
        display: block;
        &:before {
          content: attr(data-th)": "; // who knew you could do this? The internet, that's who.
          font-weight: bold;

          // optional stuff to make it look nicer
          width: 50%; // magic number :( adjust according to your own content
          display: inline-block;
        }
      }
    }
    tr:nth-child(odd){
      background-color: #f4f4f5;
    }
    tr:nth-child(even){
      background-color: #eaeaea;
    }
  }
}
