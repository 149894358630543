//
// General
// --------------------------------------------------

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

h1,h2,h3,h4,h5,h6,p,li,pre,blockquote,a,time,span,svg,input,address,button {
  opacity: 0;
  .wf-active &,
  .wf-inactive & {
    opacity: 1;
  }
}

body {
  background-color: palette(white);

  &.nav-is-visible {
    overflow: hidden;
  }
}

.vert-align {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  width: 100%;
}

.vert-align-handler {
  display: table;
  height: 100%;
  width: 100%;
}

.container-content-height {
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  text-align:center;
  z-index: 100;
  @extend %add-padding;

  h2 {
    text-align:center;
  }
}

.background-blue {
  background: palette(brand);
}

.content-color-light {

  * {
    border-color: palette(white);
    color: palette(white);
  }

  .btn {
    border-color: palette(white);
    color: palette(white);

    &:hover {
      background: $hover-color;
      border-color: $hover-color;
    }
  }
}

.bg-img {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size:cover;
  background-repeat: none;
  z-index: 0;
}

.img-wrap {
  position: relative;
  padding-bottom: 70%;
  overflow: hidden;
}

.notification-wrap {
  position: absolute;
  bottom:0;
  left:0;
  width:100%;
  text-align:center;
}

.notification {
  display:inline-block;
  background: palette(blue);
  @include rem(font-size,13);
  line-height: 1em;
  z-index: 100;
  text-align: center;
  width:100%;
  @include rem(max-width,170);
  color: palette(white);
  font-weight: 700;
  @include rem(padding,15, 0);
  @include border-radius(8px 8px 0 0);
  text-transform: uppercase;
  @include rem(letter-spacing,2);

  .project-gallery-item &,
  .project-snapshot & {
    position: absolute;
    @include rem(top,25);
    @include rem(left,15);
    bottom:auto;
    right:auto;
    background: #444;
    @include rem(font-size,15);
    width:100%;
    @include rem(max-width,170);
    color: palette(white);
    font-weight: 700;
    @include rem(padding, 13, 10);
    @include border-radius(20px);
    letter-spacing:0;
  }
}

#loaded {
  height: 1px;
}

.icon-arrow-right,
.icon-arrow-left{

  width: 32px;
  height: 14px;
  fill:palette(brand);
  
}

.line-holder{

  overflow: hidden;
  width: 200px;
  height: 12px;
  display: flex;

  svg{
    display: block;
    will-change:transform;
    width: 100%;
    height: 100%;
    fill:palette(brand);
    transition: transform .6s $ease-out-circ .3s;
    &.icon-line-right{
      transform: translateX(100%);
    }
    &.icon-line-left{
      transform: translateX(-100%);
    }
    .pace-done &{
      transform: translateX(0);
    }
  }

}

.icon-stroke{
  width: 100%;
  position: absolute !important;
  left:0;
  top:0;
}