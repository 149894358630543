//
// Animation
// --------------------------------------------------

// Animate in from the right
@keyframes inFromRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Animate out to the right
@keyframes outToRight {
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

// Animate in from the left
@keyframes inFromLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Animate out to the left
@keyframes outToLeft {
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

// Nav slide in down
@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}