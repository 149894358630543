.lt-ie9{
  .wrap{
    min-width: 1026px;
  }
  .alert-warning{
    border:4px solid red;
    text-align: center;
    font-size: 18px;
    padding:40px;
  }
  #mobile-primary-nav,
  .mobile-nav-trigger
  {
    display: none;
  }
  .l-region--header .l-region--navigation{
    width: 630px;
  }
  .hero.front .wrap{
    top:30%;
  }
  .hero.front{
    height: 900px;
  }
  .l-region--header .wrap>*{
    top:30%;
  }
}