$fontDir:"../fonts";

@font-face {
  font-family: 'icomoon';
  src:url('#{$fontDir}/icomoon.eot?qo04im');
  src:url('#{$fontDir}/icomoon.woff?qo04im') format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin icon{
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align:middle;
}

.icon-heart{
  @include icon();
}

[class^="icon-"]:before,
[class*="icon-"]:before{
  @include icon();
}

%icon{
  @include icon();
}

.icon-linkedin:before,
.icon-instagram:before,
.icon-facebook:before,
.icon-twitter:before{
 @include icon();
}

.icon-instagram:before,
%instagram {
  content: "\e000";
}
.icon-facebook:before,
%facebook {
  content: "\e001";
}
.icon-twitter:before,
%twitter {
  content: "\e002";
}
.icon-up:before,
%up,
%angle-up {
  content: "\e003";
}
.icon-down:before,
%down,
%angle-down {
  content: "\e004";
}
.icon-left:before,
%left,
%angle-left {
  content: "\e005";
}
.icon-right:before,
%angle-right,
%right  {
  content: "\e006";
}
.icon-check:before,
%check {
  content: "\e007";
}
.icon-clock:before,
%clock {
  content: "\e008";
}
.icon-phone:before,
%phone {
  content: "\e009";
}
.icon-plus:before,
%plus {
  content: "\e00a";
}
.icon-close:before,
%close {
  content: "\e00b";
}
.icon-menu:before,
%menu {
  content: "\e00c";
}
.icon-search-find:before,
%search {
  content: "\e00d";
}

.icon-vimeo:before,
%vimeo {
  content: "\e00e";
}
.icon-linkedin:before,
%linkedin {
  content: "\e00f";
}
.icon-pinterest:before,
%pintrest {
  content: "\e010";
}
.icon-calendar:before,
%calendar {
  content: "\e011";
}
.icon-download:before,
%download {
  content: "\e012";
}
.icon-globe:before,
%globe {
  content: "\e013";
}
.icon-location:before,
%location {
  content: "\e014";
}
.icon-print:before,
%print {
  content: "\e015";
}
.icon-up-open-big:before,
%up-big {
  content: "\e016";
}
.icon-right-open-big:before,
%right-big {
  content: "\e017";
}
.icon-left-open-big:before,
%left-big {
  content: "\e018";
}
.icon-down-open-big:before,
%down-big {
  content: "\e019";
}
.icon-credit-card:before,
%cc {
  content: "\e01a";
}
.icon-thumbs-down:before,
%thumbs-down {
  content: "\e01b";
}
.icon-thumbs-up:before,
%thumbs-up {
  content: "\e01c";
}
.icon-eye:before,
%eye {
  content: "\e01d";
}

.icon-arrow:before,
%right-arrow {
  content: "\e900";
}
.icon-download2:before,
%download2 {
  content: "\e901";
}
.icon-pin:before,
%pin {
  content: "\e902";
}
.icon-play:before,
%play {
  content: "\e903";
}

.icon-heart:before,
%heart {
  content: "\e904";
}


.icon-user:before {
  content: "\e908";
}

.icon-dot:before {
  content: "\e906";
}
.icon-close2:before {
  content: "\e905";
}
.icon-triangle-down:before,
%triangle-down {
  content: "\e907";
}
.icon-user:before {
  content: "\e908";
}
.icon-world:before,
%world {
  content: "\e909";
}


