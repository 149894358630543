//
// Header
// --------------------------------------------------
//
$header-height-default: 119px;
$header-height-mobile:  95px;
$header-height-float:   0.7;
$header-background:     palette(white);
//
$logo-width-default:    rem-calc(239);
$logo-width-mobile:     rem-calc(180);
//


.hamburger {
  outline: 0;
}

.float-wrapper,
.l-region--header {
  height: rem-calc($header-height-mobile);
  width: 100%;

  @include from(sd) {
    height: rem-calc($header-height-default);
  }

  // option
  @if $nav-in-hero == true {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.mobile-nav-trigger-handler {
  z-index: 1250;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top:0;
  right:0;

  @include from(sd) {
    display:none;
  }
}

.l-region--header {
  @include add-padding;
  position: relative;
  z-index: 100;
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(20);

  display: flex;
  flex-flow: row nowrap;
  justify-content:space-between;
  align-items: center;
  transition: background-color 0.3s $ease-out-cubic, height 0.2s $ease-out-cubic;

  // option
  @if $nav-in-hero == true {
    background-color: transparent;
  } @else {
    background-color: $header-background;
  }

  .header-float & {
    position: fixed;
    transform: translateY(-100%);
    border-bottom: 1px solid palette(grey, light);
    transition: transform 0s linear;
    background-color: $header-background;
    height: rem-calc($header-height-mobile * $header-height-float);

    @include from(sd) {
      height: rem-calc($header-height-default * $header-height-float);
    }

    &.header-up,
    &.header-down {
      transition: transform 0.4s $ease-out-cubic;
    }

    &.header-up {
      transform: translateY(-100%);
    }

    &.header-down {
      transform: translateY(0%);
    }
  }

  .logo {
    display: block;
    width: $logo-width-mobile;

    @include from(d) {
      width: $logo-width-default;
    }

    svg {
      max-width: 100%;
      height: auto;
      fill: palette(white);
    }
  }

  .desk {
    display: none;
  }

  .meta,
  .l-region--navigation {
    display: none;

    @include from(sd) {
      display: block;
    }
  }

  @include from(sd) {

    .l-region--navigation {
      flex:1 0 auto;

      >ul {
        display: flex;
        flex-flow: row nowrap;
        justify-content:flex-end;
        align-items: center;
      }
    }

    .desk {
      display: block;
    }

    .meta {
      display: flex;
      flex-flow: row nowrap;
      justify-content:space-around;
      align-items: center;

      .icon-search {
        width: rem-calc(20);
        height: rem-calc(20);
      }
      .button {
        @include rem(margin-left, 15);
      }
    }
  }

  @include from(ld) {

    .search {
      @include rem(margin-right, 40);
    }
  }
}
