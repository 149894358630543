//
// Tile Slideshow
// --------------------------------------------------

.tile-slideshow-handler{
	position: absolute;
	top:0;
	bottom:0;
	width:100%;
	.content-align-left &{
		right:0;
	}
	.content-align-right &{
		left:0;
	}
	.tile-slideshow,
	.slick-track,
	.slick-list,
	.slide-item{
		height:100%;
		width:100%;
	}
	.slide-item{
		position: relative;
	}
}
.slick-dots{
	position: absolute;
	bottom:4%;
	left:0;
	right:0;
	margin: 0 auto;
	padding:0;
	text-align:center;
	li{
		list-style: none;
		display:inline-block;

		margin: 0;
		padding:0;
		@include rem(margin-left,15);
		&:first-child{
			margin-left:0;
		}

		button{
			color:transparent;
			background:palette(white);
			width:15px;
			height:15px;
			border:1px solid palette(brand);
			display:block;
			@include border-radius(200px);
			padding: 0;
			margin:0;

		}
		&.slick-active{
			button{
				background: palette(brand);
			}
		}
	}

}