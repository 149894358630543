//
// Buttons
// --------------------------------------------------

.button,
.btn {
  @include button;
}

.btn-layout-wrap {
  padding-top: 0;
  text-align: center;
}

.content-block.buttons {

  .btn-layout-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .btn {
    @include flex(1 0 auto);
    @include rem(margin-right, 10);
    @include rem(margin-bottom, 10);
    outline: 0;
    &:last-of-type{
      margin-right: 0;
    }
    @include to(pt) {
      @include flex(1 0 135px);
    }
  }
}
