//
// Variables
// --------------------------------------------------

// Leap Options
$nav-in-hero: false;


//== Palette
// --------------------------------------------------

// Base colours
$_color-base-grey: lighten(#000, 33.5%);
$_color-base-brand: #ff7800;


// Colour Palette
$palettes: (
  white: (
    base: #fff,
    dark: #f1f2f3,
  ),
  black: (
    base: #000,
    brand: darken($_color-base-brand, 54%)
  ),
  grey: (
    base:    $_color-base-grey,
    light:   lighten($_color-base-grey, 13.5%),
    lighter: lighten($_color-base-grey, 58.5%),
    dark:    darken($_color-base-grey, 13.5%),
    darker:  darken($_color-base-grey, 20%),
    page:    #f2f2f2
  ),
  brand: (
    base:      $_color-base-brand,
    accent:    #222e35,
    bright:    lighten($_color-base-brand, 32.5%),
    complementary: complementary($_color-base-brand),
    secondary: secondary($_color-base-brand),
    tertiary:  tertiary($_color-base-brand)
  )
);


//== Colours
// --------------------------------------------------

// Typography
$text-color:    palette(grey, dark);
$hover-color:   palette(brand, accent);
$border-color:  palette(grey, light);


//== Typography
// --------------------------------------------------

// Weights
$font-weight-thin:       100;
$font-weight-light:      300;
$font-weight-regular:    400;
$font-weight-medium:     500;
$font-weight-semi-bold:  600;
$font-weight-bold:       700;
$font-weight-extra-bold: 800;
$font-weight-black:      900;

$body-font-weight:       $font-weight-regular;
$header-font-weight:     200;

// Families
$font-family-freight-pro: freight-display-pro, sans-serif;
$font-family-freight-sans: freight-sans-pro, sans-serif;
$font-family-freight-sans-comp: freight-sans-compressed-pro, sans-serif;
$font-family-raleway:"raleway", sans-serif;
$font-family-bree:"bree-serif", serif;
$body-font:    $font-family-raleway;
$header-font:  $font-family-raleway;
$special-font: $font-family-bree;

// Sizes
$base-font-size: 18px;
$base-line-height: 22px;

$font-size-p: $base-font-size;
$font-size-h1-home: 65px;
$font-size-h1: 65px;
$font-size-h2: 45px;
$font-size-h3: 32px;
$font-size-h4: 20px;
$font-size-h5: 16px;
$font-size-h6: 16px;

// Spacing
$base-letter-spacing: normal;

$letter-spacing-small:  0.6px;
$letter-spacing-medium: 1px;
$letter-spacing-large:  2px;


//== Hamburger
// --------------------------------------------------
$hamburger-padding-x                       : rem-calc(0px);
$hamburger-padding-y                       : rem-calc(0px);
$hamburger-layer-width                     : rem-calc(40px);
$hamburger-layer-height                    : 4px;
$hamburger-layer-spacing                   : 4px;
$hamburger-layer-color                     : #000;
$hamburger-layer-border-radius             : 0;
$hamburger-hover-opacity                   : 0.7;
$hamburger-hover-transition-duration       : 0.15s;
$hamburger-hover-transition-timing-function: linear;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false;
$hamburger-hover-filter    : opacity(50%);

// Remove or comment out the hamburger types you don’t want
// or need, so they get excluded from the compiled CSS.
// $hamburger-types: (
//   3dx,
//   3dx-r,
//   3dy,
//   3dy-r,
//   arrow,
//   arrow-r,
//   arrowalt,
//   arrowalt-r,
//   boring,
//   collapse,
//   collapse-r,
//   elastic,
//   elastic-r,
//   emphatic,
//   emphatic-r,
//   slider,
//   slider-r,
//   spring,
//   spring-r,
//   stand,
//   stand-r,
//   spin,
//   spin-r,
//   squeeze,
//   vortex,
//   vortex-r
// );
$hamburger-type:(
  spring
);

//== Grid
// --------------------------------------------------

// Set consistent vertical and horizontal spacing units.
$vert-spacing-unit                : 120px !default;
$vert-spacing-unit-modifier-tab   : 0.8 !default;
$vert-spacing-unit-modifier-mobile: 0.5 !default;

$horz-spacing-unit                : 40px !default;
$horz-spacing-unit-modifier-tab   : 0.8 !default;
$horz-spacing-unit-modifier-mobile: 0.5 !default;

$max-content-width                :700px;

$vert-typographic-spacing         :45px;

$noquery                          : '.lt-ie9';

$grid-padding                     : 2rem;
$slicer-anti-overlap-corrections  : -1px;

// Breakpoints
$slicer-breakpoints: 0        550px       768px       960px       1025px      1250px      1440px       1750px;

//                   └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘
// Slice numbers:         1           2           3           4           5           6           7           8
$slicer-breakpoint-names:'pp'        'lp'        'pt'        'lt'       'sd'        'd'         'ld'        'vld';
