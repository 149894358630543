//
// Button Mixins
// --------------------------------------------------
//
$button-background-default: palette(brand);
$button-color-default:      palette(white);
//
$button-font:           $special-font;
$button-font-size:      rem-calc(18);
$button-font-weight:    $font-weight-bold;
$button-letter-spacing: rem-calc(1);
$button-border-radius:  rem-calc(0);
$button-padding:        rem-calc(17 20 20 20);
//


@mixin button($background: $button-background-default, $color: $button-color-default) {
  color: $color;
  background: url("../images/button-bg.png") repeat center center $background;
  text-transform: uppercase;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  font-family: $button-font;
  padding: $button-padding;
  position: relative;
  border-radius: $button-border-radius;
  line-height: 1;
  text-align: center;
  box-sizing: border-box;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content:center;
  align-items: center;
  border:0;
  transition-property: background-color, color, border;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  span{
    display: block;
  }
  @include appearance(none);

  @include from(d) {
    padding: $button-padding;
  }

  &:hover {
    color: $color;
    background-color: darken($background, 10%);

  }

  &.white {
    background-color: palette(white);

    color: $background;

    &:hover {
      color: palette(white);
      border-color: $background;
      background-color: $background;
    }

    &.transparent {
      background-color: transparent;
      color: palette(white);

      &:hover {
        background-color: $color;
        border-color: $color;
        color: palette(black);
      }
    }
  }

  &.invert {
    background-color: $background;


    &:hover {
      background-color: $color;
      color: $background;
    }
  }

  &.transparent {
    background-color: transparent;
    border-color: $background;

    &:hover {
      background-color: $color;

      color: $background;
    }
  }

  svg {
    @include rem(left, 10);
    @include rem(margin-right, 5);
    fill:#fff;
    @include rem(width, 20);
    @include rem(height, 20);

    @include from(d) {
      @include rem(margin-right, 10);
    }
  }
}