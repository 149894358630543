//
// Content Columns
// --------------------------------------------------

.content-block.content-columns {
  .wrap{
    @include add-padding-no-desk;
  }
  h2.header{
    margin-bottom: rem-calc(10);
    text-align: center;
  }
  h3{
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: rem-calc(40);
    span{
      margin-bottom: rem-calc(10);
    }
    @include from(lt){
      font-size: rem-calc(80);
    }
    .icon-line-right{
      width: 100px;
      height: 10px;
      overflow: visible;
    }
    &.sub-header{
      font-size: rem-calc(23);
      font-weight: 400;
      margin-bottom: rem-calc(10);
      span{
        margin-bottom: 0;
      }
    }
  }
  
  h2.header,
  h3.subheader{
     text-align: center;
  }
  h2.header{
    font-family: $special-font;
    text-transform: uppercase;
    font-weight: 600;
    color:#000;
    font-size: rem-calc(16);
    letter-spacing: 2px;
  }
  h3.subheader{
    color:#000;
    font-size: rem-calc(40);
  }
  .line-holder{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem-calc(40);
  }
  .container {

    @include from(lt) {
      display: flex;
    }
  }
  
  .column {
    margin-bottom: rem-calc(20);
    width: 100%;
    @include to(lp){
      &:last-child{
        margin-bottom: 0;
        .inner{
          padding-bottom: 0;
        }
      }
    }
    @include from(lt) {
      margin-bottom: 0;
    }
  }
  p:last-of-type{
    margin-bottom: 0;
  }
  .link-handler{
    margin-top: rem-calc(20);
    .button{
      width: 100%;
    }
  }

  .inner {
    @include rem(padding-left, 20);
    @include rem(padding-right, 20);
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(500);
    padding-bottom: rem-calc(30);
    text-align: center;

    @include from(lt) {
      @include rem(padding-left, 40);
      @include rem(padding-right, 40);
    }
  }

  

  figure {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin-bottom: rem-calc(30);
    img {
      display: block;
    }

    &.svg {
      @include rem(padding, 30);
      padding-bottom: 0;
      .inner{
        padding:0;
        margin:0;
        height: rem-calc(86);
        width: rem-calc(86);
        display: flex;
        align-items: center;
        justify-content: center;
        border:2px solid #FFF;
        border-radius: 50%;
      }
      svg{
        position: relative;
        &.icon-coffee{
          right:-3px;
        }
        &.icon-hand{
          right:3px;
        }
      }
    }

    svg {
      height: rem-calc(45);
      width: rem-calc(45);
      
    }
  }

  &.has-2 {

    .column {

      @include from(lt) {
        border: 0;
      }
    }
  }

  &.has-3 {

    .column {

      @include from(lt) {

        &:nth-child(3n) {
          border-right: 0;
        }
      }
    }
  }
  &.has-4,
  &.has-5{
    h3{
      font-size: rem-calc(32);
      @include from(d){
        font-size: rem-calc(36);
      }
    }
    .inner{
      padding-bottom: 0 !important;
    }
  }
  .icon-stroke{
    fill:#FFF;
  }
  &.background-color-dark{
    figure.svg .inner{
      border-color: palette(brand);
    }
  }
  //home page styling

  &.background-color-dark{
    .home &{
      @include background-noise('left','white');
      &::after{
        background-size: inherit;
        opacity: .8;
        background-position: center left;
      }
      background-color: palette(brand);

      .content {
        font-family: $special-font;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.3;
        
      }

      .icon-stroke{
        fill:palette(brand);
        background-color: #fff;
      }

      h3{
        color:#FFF;
      }

      .icon-line-right{
        fill:#FFF;
      }

      .line-holder{
        svg{
          fill:#FFF;
        }
      }

    }
  }

  .not-front &{
    @include from(lt){
      .column{

      }
      .inner{
        height: 100%;
        position: relative;
        padding-bottom: rem-calc(80);
        .link-handler{
          position: absolute;
          bottom:0;
          width: 70%;
          left:50%;
          margin-left: -35%;
        }
      }
    }
  }
}
