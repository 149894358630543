.programs-list.content-block{
  padding-top: 0;
  padding-bottom: rem-calc(40);
  figure{
    overflow: hidden;
  }
  img{
    transition: transform .6s $ease-out-circ;
    display: block;
    width: 100%;
    @include object-fit(cover);
    
    @include from(pt){
      height: 400px;
    }
    @include from(d){
      height: 500px;
    }
  }
  svg{
    display: block;
    transition: transform .6s $ease-out-circ;
  }
  h3{
    color:#FFF;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: $special-font;
    margin-bottom: rem-calc(10);
    font-size: rem-calc(20);
    @include from(pt){
      font-size: rem-calc(25);
    }
  }
  a{
    font-family: $special-font;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover{
      color:palette(brand);
    }
  }
  .programs.list{
    position: relative;
    &:hover{
      img{
        transform:scale(1.05);
      }
      svg{
        transform:translateX(30%);
      }
    }
  }
  .content {
    text-align: center;
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(30);
    background-color: rgba(#000, 0.6);
    min-height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    .inner{
      padding-left: rem-calc(10);
      padding-right: rem-calc(10);
    }
    @include from(pt){
      min-height: 110px;
    }
  }

  .nav{
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    display: flex;
    justify-content: center;
    align-items: center;
    .button-prev,
    .button-next{
      cursor:pointer;
      width: rem-calc(45);
      height: rem-calc(45);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: palette(brand);
      border-radius: 50%;
      will-change:opacity;
      transition:opacity .45s $ease-out-circ;
      &.swiper-button-disabled{
        opacity: 0.2;
      }
      svg{
        fill:#FFF;
      }
    }
    span{
      margin-left: rem-calc(20);
      margin-right: rem-calc(20);
      text-transform: uppercase;;
      font-family: $special-font;
      font-weight: 600;
      font-size: rem-calc(20);
    }
  }
}