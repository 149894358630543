//
// Navigation
// --------------------------------------------------
//
$nav-link-color: $text-color;
$nav-link-spacing: rem-calc(15);
$nav-font-size: rem-calc(18);
$nav-font-weight: $font-weight-regular;
//

.l-region--navigation {

  @include from(sd) {
    flex: 1 0 auto;

    >ul {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }
  }

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .nav {
    @include pie-clearfix;

    .menu-search {

      a {
        @include squish-text;

        &:before {
          @extend %icon;
          @extend %search;
          color: palette(white);
          font-size: 14px;
        }
      }
    }

    > li {
      display: inline-block;
      position: relative;

      &.active {
        &::after{
          content: "";
          position: absolute;
          width: 100%;
          height: 10px;
          bottom:-10px;
          left:0;
          background-repeat: no-repeat;
          background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.844 11.91"><defs><style>.cls-1 { fill: #ff7800; fill-rule: evenodd; }</style></defs><path id="Shape_1_copy" d="M199.83 6.63q-.076.705-.147 1.41c-.39.07-.782.13-1.173.2-.656-.61-1.145.42-2.05-.4a1.158 1.158 0 0 0 .293-.81h-.146c-2.15 2.44-4.076.96-6.734 2.21-3.85 1.82-8.52-.29-13.762.2-2.763.27-6.49.2-9.366.2-.782.14-1.562.27-2.344.41a27.042 27.042 0 0 0-5.858-.81c-3.344.34-6.7-2.43-10.393-1-1.185.46-1.284-.47-3.076 0-6.065 1.6-13.21-.61-18.008 0l-8.345.8c-4.5-.84-9.276-1.2-13.907.2-1.972.6-4 .01-5.563.6-.723.28-1.68-.35-2.2-.4-.69-.06-1.633.66-2.635.4-3.042-.76-6.02-.22-8.493.21-3.317-.34-6.636-.67-9.954-1.01-4.544-.48-11.34-.96-15.518 0l-6.15-.4c-4.17.48-9.79.26-14.053 1.41-.69.19-2.48-.3-2.93-.21-2.684.58-5.37-.59-7.76 0a34.317 34.317 0 0 1-8.2.61c-1.02.07-2.048.13-3.07.2a23.01 23.01 0 0 0-5.272-.2c-1.514-.2-3.026-.4-4.54-.61-1.484.42-2.4 1.74-3.807 2.02-2.98.59-4.238-3.32-4.682-6.63 1.078-1.46.962-3.47 3.072-3.62 2.138-1.45 7.3.4 10.836.6 6.27.35 10.707.7 16.835-.6 2.16-.46 4.54.81 6.732-.2L37.17 0h.145l1.173 1.41c1.23.61 2.718-.59 3.806-.41 1.6.28 4.354.82 6.588.21C51.06.61 55.33.76 57.957.8A107.343 107.343 0 0 0 70.55.4l2.2.6A23.977 23.977 0 0 0 80.8.8c.48.7 7.984 1.78 10.1 1.01 1.7-.62 4.108.14 5.857 0A9.16 9.16 0 0 0 100.27 1a6.75 6.75 0 0 0 2.05.41l8.052-.81a19.178 19.178 0 0 1 6.588.4c2.28.59 5.047-.5 7.614-.6h4.98c3.386-.87 6.978.38 10.54.2 7.842-.38 14.362-.77 22.987 1.61a10.025 10.025 0 0 0 4.83-.4l4.687.6c1.853-.13 3.708-.26 5.562-.4 2.806.73 6.17.84 8.052 1.21 1.19.23 2.68-.98 3.37-.81a14.033 14.033 0 0 0 1.316.81c3.74 1.11 6.26-1.01 7.9 3.01.326-.26.49-.54.734-.2.1.2.196.4.295.6zm-1.9-.2c-.147.07-.293.13-.44.2.05.07.1.14.147.2.1.07.194.14.292.2v-.6z" data-name="Shape 1 copy" class="cls-1"/></svg>');
        }
        >a { }
      }

      >a { }
    }

    a {
      display: block;
      font-size: $nav-font-size;
      font-weight: 600;
      position: relative;
      text-align: center;
      padding: 0 ($nav-link-spacing * .5);

      @include from(sd) {
        padding: 0 ($nav-link-spacing * .8);
      }

      @include from(d) {
        padding: 0 $nav-link-spacing;
      }

      // option
      @if $nav-in-hero == true {
        @include link-color(palette(white), darken(palette(white), 15%));
      }
      @else {
        @include link-color($nav-link-color);
      }

      .header-float & {
        @include link-color($nav-link-color);
      }
    }

    // Other Levels
    .sub-menu {
      @include rem(top, 40);
      @include rem(width, 400);
      @include rem(margin-left, -200);
      background-color: palette(white);
      box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
      left: 50%;
      opacity: 0;
      padding: 10px 15px;
      position: absolute;
      transition: transform .4s ease, opacity .2s ease, visibility .5s ease;
      transform: translateY(20px);
      visibility: hidden;
      z-index: 10;

      &::before {
        @include triangle(30px 15px, palette(white), up);
        content: '';
        left: 50%;
        position: absolute;
        top: -15px;
        transform: translateX(-50%) translateY(100%);
        transition: all .4s ease;
        z-index: 1;
      }

      a {
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: left;

        &:hover { }
      }

      .active {

        a { }
      }

      li:last-of-type {

        a {
          border-bottom: 0;
        }
      }
    }

    .hover-active {
      .sub-menu {
        opacity: 1;
        transform: translateY(0%);
        visibility: visible;

        &::before {
          transform: translateX(-50%) translateY(1%);
        }
      }
    }

    .menu-item-has-children:before {
      @extend %icon;
      @extend %angle-down;
      @include rem(bottom, -15);
      @include transition(all .45s ease);
      color: palette(brand);
      font-size: 20px;
      height: 20px;
      left: 50%;
      margin-left: -10px;
      position: absolute;
      transition: all .5s ease;
      width: 20px;
      will-change: transform;
    }

    .menu-item-has-children {
      &.hover-active {
        &::before {
          opacity: 0;
          transform: translateY(-30%) rotateZ(180deg);
        }
      }
    }
  }
}
