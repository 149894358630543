//
// Banner
// --------------------------------------------------

.content-block.image-banner {

	.image {
		position: relative;
	}

	&.no-content {

		.image {
			padding-top: 65%;

			@include from(lt) {
				padding-top: 33%;
			}
		}
	}

	&.has-content {
		h2 {
			text-align: center;
		}
		p{
			margin-bottom: rem-calc(20);
		}
		.buttons {
			margin-top: rem-calc(40);
		}

		.buttons {
			text-align: center;
		}

		.image {
			padding-top: 45%;
		}

		.inner {
			padding-top: rem-calc(70);
			padding-bottom: rem-calc(70);
			@include rem(max-width,980);
			margin-left: auto;
			margin-right: auto;
			@include from(pt) {
				background-color: transparent;
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		@include from(pt) {
			height: 60vh;
			min-height: 750px;
			position: relative;
			display: flex;
			// justify-content: center;
			align-items: center;

			h2,p {
				color:#FFF;
				text-align: center;
			}

			.image {
				padding-top: 0;
				height: 100%;
				width: 100%;
				left:0;
				top:0;
				position: absolute;
			}

			.inner {
				position: relative;
				z-index: 1;
			}
		}
	}
}