//
// Block Quote
// --------------------------------------------------

.content-block.block-quote{
	font-family: $header-font;
	text-align: left;

	.inner{
		//@include rem(padding, 40, 0);
		border-left: rem-calc(10) solid $border-color;
		padding-left: rem-calc(20);
	}
	.blockquote{
		font-style: italic;
		@include rem(font-size, 18);
		margin:0;
		@include from(pt){
			@include rem(font-size, 24);
		}
		p{
			line-height: 1.5;
		}
	}
	.blockquote-author{
		font-family: $body-font;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-style: normal;
		@include rem(margin-top, 30);
		@include rem(font-size, 16);
	}
}