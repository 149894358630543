//
// Social
// --------------------------------------------------

.social {
  display: flex;
  flex-flow: row nowrap;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  a {
    margin-right: rem-calc(10);
    width: rem-calc(20);
    height: rem-calc(20);

    &:last-child {
      margin-right:0;
    }
  }
}
