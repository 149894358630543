//
// Leap Layout
// --------------------------------------------------

.wrap {
  @extend %l-constrained;
  @extend %add-padding;
  @include pie-clearfix;
}

.small-wrap {
  @extend %add-padding;
  margin-left: auto;
  margin-right: auto;
  max-width: 830px;
}

.lock,
.overly-lock {
  overflow: hidden;
}

.l-page {
  min-height: 100%;
  position: relative;
  z-index: 3;
}

.sidebar-primary {

  @include from(pt) {

    main.main {
      @include grid-span(1, 1, $grid: 3 1 1, $gutter: .1);
    }

    aside.sidebar {
      @include grid-span(1, 3, $grid: 3 1 1, $gutter: .1);
    }
  }

  main.main {
    @include rem(margin-bottom, 60);
  }
}
