.gmap-info-box,
.gm-style-iw{
  h4{
    margin-bottom: rem-calc(10) !important;
    text-transform:uppercase;
  }
  .info-box-address{
    display: block;
    letter-spacing: 1px;
    max-width: rem-calc(330);
    line-height: 1.2
  }
}
.content-google-map{
  display: flex;
  align-items: stretch;
  margin-top: rem-calc(10);
}
.map-legend{
  flex:0 1 25%;
  background-color: palette(brand,accent);
  color:#FFF;
  @include from(pt){
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
  }
  ul,li{
    margin:0;
    padding:0;
    list-style-type: none;
  }
  li{
    cursor: pointer;
    padding:rem-calc(5 5);
    transition: all .5s $ease-out-circ;
    &.active-pin{
      background-color: palette(brand);
      color:#FFF;
    }
  }
}
.map-legend-items{
  line-height: 1.2;
  //font-size: rem-calc(12);
  @include responsive-font(2vw, 12px, 22px, 22px);
}

.map-handler{  
  flex:1 0 75%;
  width: 75%;
}

#map-canvas{
height: 100%;
width: 100%;
min-height: 50vh;
}