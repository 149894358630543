//
// Search
// --------------------------------------------------

.search-feature {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  background: rgba(palette(black),.8);
  z-index: 300;
  top: 0px;
  opacity: 0;
  display: none;
  @include rem(padding-top, 10);
  @include rem(padding-bottom, 10);
  @include add-padding;
  @include clearfix;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nocsstransform & {
    left: -200%;
  }

  form {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    height: 80px;
    position: relative;
  }

  .search,
  .searchsubmit {
    height: 100%;
  }

  .searchform {
    opacity: 0;
  }

  .search{
    width: 100%;
    float: left;
    background: transparent;
    border-bottom: 3px solid white;
    color: white;
    box-sizing: border-box;
    font-size: 30px;
    height: 80px;
    padding-right: 50px;
    @include border-radius(0);

    @include from(pt){
      font-size: 50px;
    }
  }

  .searchsubmit{
    position: absolute;
    background: transparent;
    color:#FFF;
    right: 0;
    font-size: 52px;
  }

  .close-search {
    position: absolute;
    width: 50px;
    top: 20px;
    background: transparent;
    right: 0;
    transform: translateX(-50%);
    color: palette(white);
  }

  button {
    padding:0;
    display: block;
    margin: 0;
    height: 40px;
    @include appearance(none);
    border: 0;
    font-size: 45px;
    outline: none;

    i::before{
      display: block;
      width: 100%;
    }
  }

  input{
    @include box-shadow(0,0,0,0);
    display: inline-block;
    margin: 0;
    @include appearance(none);
    font-family: $header-font;
    padding:20px 15px;
    font-weight: 700;
    outline:0;
    border:0;
  }
}
main{

  .search{

    >header{
      text-align: center;
      padding-top: 35px;
      padding-bottom: 35px;

      h3{
        font-size: 20px;
      }
    }
  }
}
