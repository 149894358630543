//
// Mobile Navigation
// --------------------------------------------------

.mobile-nav-trigger {
  position: absolute;
  bottom: auto;
  font-weight: 400;
  right: 12px;
  letter-spacing: 1px;
  line-height: 1.1em;
  text-transform: uppercase;
  font-size: 24px;
  color: palette(brand, accent);
  overflow: visible;
  z-index: 9999;

  @include from(d) {
    display: none;
  }

  span {
    top: 50%;
    @include rem(margin-top,-4);
    transition:background .3s .3s;

    &,
    &:before,
    &:after {
      right: 0;
      position: absolute;
      display: inline-block;
      height: 3px;
      width: 24px;
      background: palette(brand, accent);
      @include transform(translateZ(0));
    }

    &:after,
    &:before {
      content: '';
      backface-visibility: hidden;
      transform-origin: 0% 50%;
      transition: transform .3s .3s, background .3s .3s;
    }

    &:before {
      top: -6px;
    }

    &:after {
      top: 6px;
    }
  }

  &.nav-is-visible {

    span {

      &:before,
      &:after {
        background: palette(brand, accent);
      }

      &:before {
        @include transform(translateX(4px) translateY(-3px) rotate(45deg));
      }

      &:after {
        @include transform(translateX(4px) translateY(2px) rotate(-45deg));
      }
    }
  }
}

.mobile-primary-nav,
.mobile-secondary-nav {
  margin: 0;
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }
}

.mobile-primary-nav {
  position: fixed;
  top: 0;
  right: 0;
  border-left: 1px solid palette(grey, light);
  transition: transform .4s $ease-out-cubic;
  width: 100%;
  height: 100%;
  background: palette(white);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 82px 25px;
  -webkit-overflow-scrolling: touch;
  z-index: 1001;
  transform: translateX(100%);

  @include from(lp) {
    width: 60%;
  }

  @include from(lt) {
    width: 440px;
  }

  &.is-hidden {
    transform: translateX(100%);
  }

  &.moves-out {
    > li > a {
      transform: translateX(-100%);
      opacity: 0;
    }
  }

  &.nav-is-visible {
    transform: translateX(0);
    z-index: 100;

    @include to(lt) {
      visibility: visible;
    }
  }

  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translateZ(0);
    will-change: transform, opacity;
    transition:transform .3s, opacity .3s;
  }

  nav.main {
    border-top: 1px solid #8b9194;
    margin-bottom: 34px;
  }

  .social {
    @include rem(margin-top, 20);

    a {
      color: palette(brand);
      border: none;
      float: left;
      @include rem(font-size, 18px);
      padding: 0;
      @include rem(margin,0);
      @include rem(margin-left, 10);
      width: 25px;
      height: 25px;
      &:hover {
        color:palette(brand);
      }

      &:first-child {
        margin-left: 0;
      }
      svg{
        fill:palette(brand);
        width: 100%;
        height: 100%;
      }
    }
  }

  .form-handler {
    @include rem(padding, 80px, 0);

    .input-group {
      position: relative;
      display: block;
      width: 100%;
    }

    .mobile-search {
      box-sizing: border-box;
      display: block;
      @include rem(height, 50px);
      @include rem(padding, 0, 55px, 0, 15px);
      width: 100%;
    }

    .mobile-search-submit {
      position: absolute;
      right: 0;
      height: 100%;
      width: 40px;
      z-index: 0;
      top:0;
      background: transparent;
      color: palette(grey, light);
      @include box-shadow(0,0,0,0);
      @include border-radius(0);
      border: none;
      line-height: 1em;
      -webkit-appearance: none;
      border-radius: 0;

      i {
        position: relative;
        top:1px;
      }
    }
  }
}

.go-back,
.has-children {

  .menu-page-trigger {
    position: absolute;
    top:50%;
    padding: 10px;
    margin-top: -25px;
    z-index: 1;
    display: block;

    &:after {
      @extend %icon;
      font-size: 24px;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      cursor: pointer;
    }
  }
}

.has-children {
  .menu-page-trigger {
    right: 0px;

    &:after {
      @extend %angle-right;
    }
  }

  .go-back a {
    right: auto;

    &:before{
      @extend %icon;
      @extend %angle-left;
      left: 0px;
      position: absolute;
      top: 50%;
      padding: 10px;
      margin-top: -23.5px;
      z-index: 1;
      display: block;
      font-size: 24px;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      cursor: pointer;
    }
  }
}

.has-children > a {
  padding-right: 40px;

  &:before,
  &:after {
    right: 10px;
  }
}

.has-children > a,
.go-back a {
  position: relative;
}


nav.footer {
  .button {
    width: 100%;
    margin-bottom: 11px;
    color:#fff;
    border-width: 2px;

    &:hover {
      color:#FFF;
    }
  }
}

.sticky-wrapper-mobile{
  position: absolute;
  width: 100%;
  left:0;
}

#mobile-footer{
  @include opacity(1);
  display:block;
}

.no-touch.nav-is-visible {
  .mobile-nav-trigger.nav-is-visible {
    @include rem(margin-right,15);
  }
}

.mobile-primary-nav.nav-is-visible {
    z-index: 1000;
}

#mobile-primary-nav {
  .mobile-nav-trigger{
      left:auto;
      right: 35px;
      top: 26px;
      padding: 20px;

      @include to(lp) {
        right: 32px;
        top: 19px
      }

      &:after {
        content: '';
        right:auto;
        left:-54px;
      }
    }
}

.mobile-primary-nav,
.mobile-primary-nav .menu-page {
 // @include rem(padding,90, 40);
  padding-top: rem-calc(30);
  padding-bottom: rem-calc(50);
  @include add-padding;
}

#ml-menu .menu__breadcrumbs .breadcrumb-link {
  @include opacity(1);
  transition-property:opacity transform;
  transition-duration:0.25s;
  transition-timing-function:easeOutCubic;
  @include translateX(0px);

  &.not-visible {
    @include opacity(0);
    @include translateX(20px);

    &:first-child {
      @include opacity(1);
      @include translateX(0px);
    }
  }
}

.mobile-general-contact {
  @include rem(padding-top,30);
  line-height: 1.2;
  .contact-area {
    text-align: left;
     h4{
      letter-spacing: 0;
      @include rem(font-size, 18);
    }
    >ul{
      @include rem(margin-bottom, 0);
      li{
        @include rem(padding, 10, 0);
      }
    }
  }
}

.mobile-btn-handler {
  text-align:center;
  margin-top: rem-calc(40);
  .btn {
    width:100%;
    @include rem(margin-bottom, 10);
  }
}

.mobile-search-handler {
  width:100%;
  @include rem(padding-top,30);
  @include rem(padding-bottom,30);

  input[type=search] {
    width: 75%;
    outline: 0;

    @include placeholder {
      color: #999; //TODO
    }
  }

  button[type=submit] {
    background-color: palette(brand);
    color: #fff;
    font-family: $header-font;
    text-transform: uppercase;
    font-weight: 700;
    @include rem(font-size, 15);
  }

  .input-group {
    position: relative;

    .input-group-btn {
      position: absolute;
      top:0;
      bottom:0;
      right:0;
      width: 25%;
    }
  }
}

.mobile-btn-wrap {
  text-align:center;
  @include rem(padding-top,60);
}


// Pretty facking menu

.menu,
.menu__wrap,
.menu__level,
.menu__item {
  width: 100%;
  top: 0;
}

.menu {
  position: relative;
  right: 0;
  background: palette(white);
  z-index: 20000;
}

.menu__wrap {
  position: absolute;
  bottom: 0;
}

.menu__level {
  position: absolute;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  height: auto;
  list-style-type: none;
}

.menu__level--current {
  visibility: visible;
}

.menu__item {
  display: block;
  margin: 0;
}

.menu__link {
  font-weight: $font-weight-bold;
  position: relative;
  display: block;
  border-bottom: 1px solid palette(grey, light);
  text-transform:none;
  color: $text-color;
  transition: color 0.1s;
  @include rem(font-size,18);
  @include rem(padding,15, 0);

  &[data-submenu]:after {
    position: absolute;
    top: 50%;
    right: 0;
    line-height: 1em;;
    padding: 0;
    color: $text-color;
    @include rem(font-size,24);
    @include rem(margin-top,-12);
    @include icon();
    @extend %right;
  }

  &:hover,
  &[data-submenu]:hover::after {
    color: palette(brand);
  }
}

[class^='animate-'],
[class*=' animate-'] {
  visibility: visible;
}

.menu__breadcrumbs {
  position: absolute;
  left: 0;
  bottom: 100%;
  line-height: 1em;
  @include rem(font-size,10);
  @include rem(padding,30,0,0,0);
  @include rem(height,40);

  a {
    font-weight: $font-weight-bold;
    display: inline-block;
    cursor: pointer;
    vertical-align: top;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: palette(brand);
    line-height: 1em;
    position: relative;
    @include rem(font-size,10);
    @include rem(padding-right,13);
    @include rem(margin-right,3);

    &,
    &:hover {
      color: palette(brand);
    }

    &:last-child {
      pointer-events: none;
    }

    &:not(:last-child) {

      &:after {
        @include icon();
        @extend %right;
        display: inline-block;
        padding: 0;
        color: palette(brand);
        position: absolute;
        right:0;
        top: 1px;
        @include rem(line-height,10);
      }

      &:hover:after {
        color: #33353e;
      }
    }
  }
}

.menu__back,
.menu__level
.action {
  position: absolute;
  margin: 0;
  padding: 0;
  background: none;
}

.menu__back {
  cursor: pointer;
  z-index: 100;
  bottom: 100%;
  left: -6px;
  border: none;
  font-weight: $font-weight-bold;
  display: block;
  color: palette(brand);
  margin-bottom: 3px;
  line-height: 1em;
  opacity: 1;
  transition: transform 0.25s $ease-out-cubic, opacity 0.25s $ease-out-cubic;
  @include translateX(0px);
  @include rem(font-size,20);
  @include rem(padding,15,0);

  &:hover,
  &:focus {
    color: palette(brand);
    outline: none;
  }
}

button.menu__back--hidden {
  pointer-events: none;
  @include opacity(0);
  @include translateX(20px);
}


// Open and close buttons

.action {
  display: block;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.action--close {
  font-size: 1.1em;
  top: 1.25em;
  right: 1em;
  display: none;
  color: #45464e;
}


// Animations

.mobile-main-header {
  @include transform(translateZ(0));
  will-change: transform;
  position: relative;
  transition:transform 0.65s;

  &.nav-is-visible {
    @include transform(translateX(-440px));

    @include to(lt) {
      @include transform(translateX(-50%));
    }

    @include to(lp) {
      @include transform(translateX(-70%));
    }

    @include to(pp) {
      @include transform(translateX(-100%));
    }
  }

  .nav-is-fixed & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.nav-on-left {

  .l-page,
  .mobile-main-header {

    .nav-is-visible {
      @include transform(translateX(440px));

      @include to(lt) {
        @include transform(translateX(50%));
      }

      @include to(lp) {
        @include transform(translateX(70%));
      }

      @include to(pp) {
        @include transform(translateX(100%));
      }
    }
  }
}

.animate-outToRight .menu__item {
  animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

.animate-outToLeft .menu__item {
  animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

.animate-inFromLeft .menu__item {
  animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

.animate-inFromRight .menu__item {
  animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}


/* --------------------------------

support for no js

-------------------------------- */
.no-js .mobile-primary-nav {
  position: relative;
  height: auto;
  width: 100%;
  overflow: visible;
  visibility: visible;
  z-index: 2;
}