//
// Media
// --------------------------------------------------

img, media {
  max-width: 100%;
}
figure{
  margin:0
}
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
#map-canvas{
  img{
    max-width: none;
  }
  height: 520px;
  @include to(pt){
    height: 400px;
  }
  @include to(lp){
    height: 300px;
  }
}