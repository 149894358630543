@mixin background-noise($position:'bottom', $color:'white'){
  position: relative;
  >*{
    position: relative;
    z-index: 1;
  }
  &::after{
    pointer-events:none;
    content: "";
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: .5;
    @include from(pt){
      background-image:url("../images/texture-#{$position}-#{$color}.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
}