//
// Forms
// --------------------------------------------------
img.gform_ajax_spinner {
  display: none!important;
}
input[type=tel],
input[type=text],
input[type=email],
input[type=number],
input[type=search],
textarea,
select {
  appearance:none;
  border-radius: 0px;
  padding:rem-calc(15 20);
  background-color: #fff;
  border: 0;
  box-sizing: border-box;
  color: $text-color;
  display: block;
  font-family: $header-font;
  font-weight: 600;
  resize: none;
  vertical-align: middle;
  width: 100%;
  line-height: 1.3;
  font-size: 16px;
  outline: 0;
  border:1px solid palette(grey);
  @include placeholder {
    color: #6e7173;
    text-transform: uppercase;
    font-size: 13px;
  }
}
select{
  background: url("data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iM3B4IiB2aWV3Qm94PSIwIDAgNiAzIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA2IDMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIHBvaW50cz0iNS45OTIsMCAyLjk5MiwzIC0wLjAwOCwwICIvPjwvc3ZnPg==") no-repeat calc(100% - 10px) center #f1f2f3;
  background-size: rem-calc(10);
  option{
    font-size: 16px;
  }
}

select::-ms-expand {
  display: none;
}

textarea {
  @include rem(height,130);
  color: $text-color;

  .gform_wrapper & {
    color: $text-color;
  }
}

input[type=search] {
  @include appearance(none);
  @include border-radius(0);
  @include rem(padding, 10px, 15px);
  border: 1px solid palette(grey, light);
  background-color: transparent;
  width: 100%;
  vertical-align: middle;
  font-family: $header-font;
  font-weight: 400;
  resize: none;
  box-sizing: border-box;

  @include placeholder {
    color: palette(grey, light);
  }
}

input[type=submit] {
  @include button;
}

.search-submit {
  background: transparent;
  height: 100%;
  width: 100%;
  display: block;
  border: none;
  padding: 0;

  .icon-search-find {
    color: palette(grey, light);
  }
}


// Gravity Forms

.gform_validation_container {
  display: none;
}

.gfield.half-col,
.name_first,
.name_last {
  @extend %pcf;
  float: left;
  width: 48.625%;
}

.gfield.half-col.first,
.name_first {
  margin-right: 2.75%;
}

.ginput_complex {
  @extend %pcf;
}
.ginput_complex{
  @include pie-clearfix;
  span{
    display: block;
    margin-top: rem-calc(20px);
  }
  @include from(pt){
    .ginput_full{
      clear: both;
    }
    .ginput_left{
      float:left;
      width:48%;
    }
    .ginput_right{
      float:right;
      width:48%;
    }
  }
}
.gclear {
  clear: both;
}
.gfield_radio,
.gfield_checkbox{
  li{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  input{
    margin-right: rem-calc(10);
  }
}
.gfield {
  margin:0;
  padding:0;
  list-style-type: none;
  list-style-image: none;
  @include rem(margin-bottom,20);
  border: 0 !important;
  li{
    list-style-type: none;
    list-style-image: none;
  }
}

.gform_wrapper {

  .gform_title {
    @extend .beta;
    @include rem(margin-bottom, 40);
  }


  .gsection {
    margin-top: 20px;
    border-bottom: 0;
  }

  .ginput_complex label,
  .gfield_label {
    text-transform: uppercase;
    font-family: $header-font;
    font-weight: 700;
    letter-spacing: 1px;
    display:none;
  }

  .show-label {

    .gfield_label {
      display: block;
    }
  }

  .validation_message,
  .validation_error {
    font-size: 12px;
    font-weight: 600;
  }

  .validation_error{
    display: none;
  }
  form {

    .instruction {
      display: none;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    input[type=submit] {
      @include button;
    }

    .ginput_card_security_code {
      margin-top: 10px !important;
    }



    .clear-multi {

      >div {
        display:inline-block;
        margin-right: 10px;

        &:last-child {
          margin-right:0;
        }
      }

      input,select {
        margin-top: 0;
      }

      .ginput_container {
        vertical-align: middle;
      }
    }

    
  }
}
.gform_footer {
  @include rem(margin-top, 25px);
}
.gform_confirmation_wrapper {
  text-align:center;
  line-height: 1.5em;
}

// Datepicker
.ui-datepicker {
  background-color: palette(white);
  border: 1px solid palette(grey, light);
  display: none;
  padding: rem-calc(15 25 25);

  select {
    background-color: palette(grey, lighter);
    border: none;
    border-radius: 8px;
    margin-top: rem-calc(5);
    margin-bottom: rem-calc(5);
    width: 48%;

    &:first-child {
      margin-right: 4%;
    }
  }

  table {
    border: 1px solid palette(grey, lighter);
  }

  td,
  th {
    border: 1px solid palette(grey, lighter);
    text-align: center;
    width: rem-calc(40);

    a {
      display: inline-block;
      width: 100%;
    }
  }

  td {

    &:hover,
    &.ui-datepicker-current-day {

      a {
        color: palette(white);
        background-color: palette(brand);
      }
    }
  }

  .ui-datepicker-next,
  .ui-datepicker-prev {
    cursor: pointer;
    display: inline-block;
    font-size: rem-calc(15);
    font-weight: $font-weight-regular;
    text-transform: uppercase;
  }

  .ui-datepicker-prev {
    text-align: left;
    width: 50%;
  }

  .ui-datepicker-next {
    text-align: right;
    width: 50%;
  }

  .ui-datepicker-header {
    margin-bottom: rem-calc(10);
  }

  .ui-datepicker-today {
    background-color: palette(grey, lighter);
  }
}
