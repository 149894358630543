//
// Pagination
// --------------------------------------------------

.wp-pagenavi {
  text-align: left;
  position: relative;
  width: 100%;
  margin-top: 25px;
  padding-bottom: 25px;
  @include rem(max-width, 740);
  @include add-padding;
  margin-left:auto;
  margin-right: auto;

  @include from(lt) {
    padding-left: 0;
    padding-right: 0;
  }

  >* {
    display: inline-block;
    @include rem(padding,13px, 15px);
    line-height: 1;
    font-weight: 600;
    @include rem(font-size, 14);
    border: 1px solid #ccc;
    @include rem(margin-right, 5);
    @include rem(margin-bottom, 5);
  }

  .pages {
    display: none;
  }

  .current {
    background-color: #fff;
    color:#a2a8aa;
  }

  a {
    background-color: #fff;
    transition:all .3s ease;

    &:hover {
      border-color: darken(palette(brand),10%);
      background-color: palette(brand);
      color:#FFF;
    }
  }

  a.page,
  .current{ }
}