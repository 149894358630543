//
// Testimonials
// --------------------------------------------------

.testimonial-slider.content-block{
  position: relative;
  overflow: hidden;
  @include content-vertical-spacing('padding', 247);
  
  @include from(pt){
    margin-right: rem-calc(40);
    margin-left: rem-calc(40);
  }
  .swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    .inner{
      max-width: 730px;
      @include add-padding-no-desk;
      @include from(d){
        max-width: 830px;
      }
    }
    .author{
      text-align: right;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: rem-calc(15);
      color:#9ca2b0;
    }
    .content{
      color:palette(grey, dark);
      font-size: rem-calc(22);
      line-height: 1.3;
      font-style: italic;
      font-family: $special-font;
      @include from(pt){
        font-size: rem-calc(35);
      }
    }
  }
}