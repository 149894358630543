.partners-list.content-block {
  @include content-vertical-spacing('padding', 60);

  h3.header {
    font-weight: 600;
    text-transform: uppercase;
    font-size: rem-calc(15);
    letter-spacing: 2px;
    margin-bottom: rem-calc(40);
    text-align: center;
  }
}

.partner-list {
  @include add-padding-no-desk;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  max-width: rem-calc(900);
  margin-left: auto;
  margin-right: auto;

  .partner {
    display: flex;
    flex: 0 1 50%;
    margin-bottom: rem-calc(20);
    justify-content: center;
  }

  @include from(pt) {
    .partner {
      flex: 0 1 percentage(1/4);
    }
  }

  @include from(lt) {
    .partner {
      flex: 0 1 percentage(1/5);
    }
  }

  @include from(sd) {
    .partner {
      flex: 0 1 percentage(1/6);
    }
  }
}