nav.greedy {
  position: relative;
  // padding-top: rem-calc(15);
  // padding-bottom: rem-calc(15);
  display: flex;
  align-items: center;
  border-top: 1px solid $border-color;
  ul,li{
    margin:0;
    padding:0;
    list-style-type: none;
  }
}

nav.greedy button {
  align-self: stretch;
  transition: all .4s ease-out;
  outline: 0;
  border: 0;
  background: transparent;
  position: relative;
  padding:0;
  padding-right: rem-calc(20);
  svg{
    height: rem-calc(20);
    width: rem-calc(20);
    fill:palette(brand,darker);
  }
  div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
nav.greedy button.hidden {
  transition: none;
  width: 0;
  padding: 0;
  overflow: hidden;
}
nav.greedy button::after {
  // position: absolute;
  // top:-10px;
  // left:0;
  // content: attr(count);
  // display: inline-flex;
  // width: 25px;
  // height: 25px;
  // align-items: center;
  // justify-content: center;
  // background: #9f9f9f;
  // color: palette(grey,page);;
  // border-radius: 50%;
  // font-size: 14px;
  // line-height: 14px;
  // margin-left: 1rem;
  // margin-right: calc(-1rem + -8px);
}
nav.greedy ul.links {
  display: flex;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  font-size: rem-calc(18);

}
nav.greedy ul.links li {
  flex: none;
  
  a{
    display: block;
    padding: rem-calc( 10 18);
    color:$text-color;
    transition: all .45s ease;
    &:hover{
      //background-color: palette(brand);
    }
    &.active{
      //background-color: palette(brand);
      font-weight: 700;
    }
  }
}

nav.greedy ul.links li a {

}
nav.greedy ul.hidden-links {
  position: absolute;
  background: #505b5d;
  right: 0;
  top: 100%;
  z-index: 10;
  padding:rem-calc(5 15);
  transition: all .35s ease;
  opacity: 1;
  transform: translateY(0px);
  a{
    color:#FFF;
  }
}
nav.greedy ul.hidden-links.hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
}
nav.greedy ul.hidden-links li {
  padding: rem-calc(5 0);
}