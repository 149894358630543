// // Footer
// --------------------------------------------------

.l-region--footer {
  @include content-vertical-spacing();
  background-color:#212020;
  background-size: cover;
  color: palette(white);
  text-align: center;
  .icon-stroke{
    fill:#212020;
    background-color: palette(grey,page);
    .home &{
      background-color: palette(brand,accent);
    }
  }
  @include background-noise();
  a{
    color:#FFF;
    &:hover{
      color:palette(brand);
    }
  }
  .wrap{
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    >*{
      max-width: rem-calc($max-content-width);
    }
  }

  .copywrite {
    @include rem(font-size, 14);
    @include add-padding-no-desk;
    font-weight: 400;
    span{
      display: block;
    }
  }

  .social{
    margin-top: rem-calc(40);
    margin-bottom: rem-calc(30);
    display: flex;
    justify-content: center;
    a{
      display: block;
      width: 30px;
      height: 30px;
      margin-right: rem-calc(25);
      &:last-child{
        margin-right: 0;
      }
    }
  }

  .other-info{
    margin-top: rem-calc(40);
    text-transform: uppercase;
    font-size: rem-calc(11);
    text-align: center;
    letter-spacing: 1px;
  }

  svg {
    fill: palette(brand);
  }

  .newsletter{
    p{
      text-transform: uppercase;
      font-weight: 600;
      font-size: rem-calc(15);
      letter-spacing: 1px;
    }


  }
  button.button{
    white-space: nowrap;
  }
  @include at(lp){
    .gfield{
      &:first-child{
        .ginput_container{
          border-right: 1px solid $border-color;
        }
      }
    }
  }
  @include from(lp){
    .gfield{
      margin-bottom: 0;
      position: relative;
      .gfield_description{
        position: absolute;
        bottom:rem-calc(-20);
        left: 0;
        height: auto;
      }
    }
    form,
    .gform_fields{
      display: flex;
      align-items: stretch;
    }
    .gform_body{
      *{
        height: 100%;
      }
    }
    .gform_footer{
      margin-top: 0;
      .button{
        height: 100%;
      }
    }
  }
  @include from(pt){
    .gfield{
      &:first-child{
        margin-right:rem-calc(20);
      }
    }
    .gform_body{
      margin-right: rem-calc(20);
    }
  }

  // Sub Form
  .sub-form {
    input {
      border: 0;
      margin-bottom: rem-calc(20);

      @include from(lp) {
        margin-bottom: 0;
        margin-right: rem-calc(20);
      }
    }
  }
}

.living-wage {
  display: block;
  margin-bottom: rem-calc(30);

  @include to(lp) {
    width: rem-calc(200);
  }
}
