//
// Color Mixins
// --------------------------------------------------

// Link color + hover
@mixin link-color($color, $hover: lighten($color, 25%)) {
  color: $color;

  &:hover {
    color: $hover;
  }
}

// Background color + hover
@mixin hover-color($color, $hover: lighten($color, 5%)) {
  background-color: $color;

  &:hover {
    background-color: $hover;
  }
}

// Overlay
@mixin overlay($opacity: .5, $bg: palette(black), $position: absolute, $pointer-events: auto) {
  content: '';
  position: $position;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $bg;
  opacity: $opacity;
  pointer-events: $pointer-events;
}