//
// Blog
// --------------------------------------------------
.news-events.content-block{
  @include background-noise('bottom','black');
  &::after{
    opacity: .9;
    background-size: inherit;
  }
  .icon-stroke{
    fill:palette(grey,page);;
    background-color: #fff;
  }
  &.blog-page-index{
    padding-top: rem-calc(30);
  }

  h2.header{
    text-align: center;
    font-weight: 200;
    margin-bottom: rem-calc(20);
  }
  .line-holder{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem-calc(40);
  }
}


.article-list{
  @include from(pt){
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;
  }
  .image{
    overflow: hidden;
  }
  .meta{
    font-size: rem-calc(16);
    font-weight: 300;
  }
  img{
    will-change:transform;
    width: 100%;
    display: block;
    transition: transform .45s $ease-out-circ;
  }
  .fake-link{
    margin-top: rem-calc(30);
    color:palette(brand);
    text-transform: uppercase;
    font-family: $special-font;
    svg{
      margin-left: 10px;
      transition: all .5s $ease-out-cubic;
    }
    @include from(pt){
      position: absolute;
      bottom:rem-calc(30);
    }
  }
  .list{
    background-color: #fff;
    display: block;
    margin-bottom: rem-calc(20);
    color:#222e35;
    font-size: rem-calc(15);
    box-shadow: 0px 1px 7px 0px rgba(0,0,0,0.2);
    @include from(pt){
      padding-bottom: rem-calc(30);
    }
    header{
      margin-bottom: rem-calc(30);
    }
    h3{
      font-size: rem-calc(25);
      margin-bottom: rem-calc(10);
    }
    .inner{
      padding-top: rem-calc(20);
      padding-bottom: rem-calc(20);
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
      @include from(pt){
        padding-top: rem-calc(40);
        padding-bottom: rem-calc(40);
        padding-left: rem-calc(40);
        padding-right: rem-calc(40);
      }
    }
    .entry-summary{
      color:#848484;
      line-height: 1.5;
    }

    .not-front {
      @include from(pt){
        flex:0 1 47%;
        margin-bottom: 0;
      }
    }
    @include from(pt){
      flex:0 1 32%;
    }
    &:hover{
      img{
        transform: scale(1.05);
      }
      svg{
        transform:translateX(30%);
      }
    }
  }

  &.index-page{
    margin-top: rem-calc(50);
    .list{
      margin-bottom: rem-calc(40);
    }
  }
}

.article-list-wrapper{
  @include remove-content-vertical-spacing-top;
  max-width: rem-calc(750);
  .meta{
    text-transform: uppercase;
    font-size: rem-calc(13);
    letter-spacing: 2px;
    font-weight: 300;
  }
  .list{
    margin-bottom: rem-calc(30);
    padding-bottom: rem-calc(30);
    border-bottom: 1px solid $border-color;
    &:last-child{
      border-bottom: 0;
    }
    @include from(pt){
      display: flex;
      justify-content: space-between;
      align-items: center;
      .image{
        flex:0 1 20%;
      }
      .container{
        flex:0 1 75%;
        margin-left: auto;
      }
    }
  }
}

.filter {
  @include add-padding;
  padding-bottom: rem-calc(30);
  padding-top: rem-calc(30);
  text-align: center;

  a.blog-filter {
    @include link-color(palette(white));
    display: inline-block;
    font-size: rem-calc(18);
    margin: rem-calc(0 15);
    opacity: .8;
    position: relative;
    text-transform: uppercase;

    &,
    &:after {
      transition: opacity .2s ease-in-out, transform .2s ease-in-out;
      transition-delay: .2s;
    }

    &:after {
      background-color: palette(white);
      bottom: 0;
      content: '';
      height: rem-calc(2);
      left: 0;
      opacity: 0;
      position: absolute;
      transform: translateY(-100%);
      width: 100%;
    }

    &.active,
    &:hover {
      opacity: 1;

      &,
      &:after {
        transition: opacity .2s ease-in-out, transform .2s ease-in-out;
        transition-delay: 0s;
      }

      &:after {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.wp-pagenavi {
  margin-top: 0;
  padding-bottom: rem-calc(40);
  padding-top: rem-calc(30);
  text-align: center;

  .nextpostslink,
  .previouspostslink {
    background-color: transparent;
    border: 0;
    font-size: 40px;
    padding: 0;
    vertical-align: bottom;

    &:hover {
      background-color: transparent;
      color: palette(brand, two);
    }
  }
}

.post {

  .aligncenter {
    display: block;
    margin: 0 auto;
  }

  .alignright {
    display: block;
    margin–right: 0;
    margin-left: auto;
  }

  .alignleft {
    display: block;
    margin–right: auto;
    margin-left: 0;
  }
}
