//
// Typography
// --------------------------------------------------

html {
  font-family: $body-font;
  color: $text-color;
  line-height: 1.8;
  font-size: 16px;
  font-weight: $body-font-weight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include from(pt) {
    font-size: $base-font-size;
  }

  // @include from(vld) {
  //   font-size: 19px;
  // }

  &.lt-ie9 {
    font-size: $base-font-size;
  }
}


// Links

a {
  // font-weight: $font-weight-bold;
  text-decoration: none;
  // @extend %dft;
  @include link-color(palette(brand));
}


// Headings

h1,h2,h3,h4,h5,h6,p,ul,ol,span {
  font-variant-ligatures: common-ligatures;
  -moz-font-feature-settings: "liga","clig","lnum";
  -ms-font-feature-settings: "liga","clig","lnum";
  -webkit-font-feature-settings: "liga","clig","lnum";
  font-feature-settings: "liga","clig","lnum";
}



h1,h2,h3,h4,h5,h6,p,ul,ol {
  margin-top: 0;
  &:not(:last-child) {
    margin-bottom: rem-calc(20);
  }
}

p {

}

h1, .alpha,
h2, .beta,
h3, .gamma,
h4, .delta,
h5, .epsilon,
h6, .zeta {
  font-family: $header-font;
  font-weight: $header-font-weight;
  line-height: 1.3;
}

h1, .alpha {
  font-size: rem-calc($font-size-h1 * .6);

  .home-hero & {
    font-size: rem-calc($font-size-h1-home * .6);
  }

  @include from(pt) {
    font-size: rem-calc($font-size-h1);

    .home-hero & {
      font-size: rem-calc($font-size-h1-home);
    }
  }

  &.header {
    color:$text-color;
  }

  .banner & {
    color:palette(white);
  }
}

h2, .beta {
  font-size: rem-calc($font-size-h2);
  line-height: 1.125em;


  @include to(lp) {
    @include rem(font-size, 28px);
  }
}

h3, .gamma {
  font-size: rem-calc($font-size-h3);

}

h4, .delta {
  font-size: rem-calc($font-size-h4);

}

h5, .epsilon {
  font-size: rem-calc($font-size-h5);

}

h6, .zeta {
  font-size: rem-calc($font-size-h6);

}

hr {
  border: 0;
  height: 1px;
  margin: 25px 0;
}


// Paragraphs

p,
%p {
  -ms-word-break: normal;
  -ms-word-wrap: normal;
  word-break: normal;
}

strong {
  font-weight: 700;
}

pre {
  font-size: 11px;
}

q {
  font-style: italic;
}


// Marks

mark,
.marker {
  padding: 0.1em 0.5em;
  background: palette(brand);
  color: #fff;
  @include rem(font-size, 12px);
  text-transform: capitalize;
  @include border-radius(10px);
}
