//
// Lists
// --------------------------------------------------

%list-link-style{
  font-family: $header-font;
  font-weight: 700 !important;
  color: $text-color;
  position: relative;
  display: block;
  &:hover{
    color: $hover-color;
  }
}
%list-container{
  overflow: hidden;
  @include rem(padding, 15, 0);
  border-top: 1px solid #bdc3cd;
  &:last-child{
    border-bottom: 1px solid #bdc3cd;
  }
}


.content-block.list {
    .list-wrap {
      @include pie-clearfix;
      margin:0;
      padding:0;
      &.col-2{
        @include from(lp){
          column-count:2;
        }
      }
    }

    .icon {
      display: block;
      color:palette(brand);
      position: absolute;
      left:0;
      top:50%;
      font-size: 20px;
      height: 20px;
      margin-top: -11px;

      .icon-heart-outlined {
        font-size: 21px;
      }

      i {
        display: block;
        height: 20px;

        &::before {
          vertical-align:top;
        }
      }
    }

    .list-item {
      break-inside: avoid;
      position: relative;
      margin:0;
      @include rem(padding, 10,0,10,0);
      list-style-type: none;
      border-bottom: 1px solid $border-color;
      justify-content:flex-start;
      align-items:center;
      display: inline-flex;
      width: 100%;
      flex-flow: row nowrap;

      a {

        &.link-list {
          outline:0;
          width: 100%;
          font-weight: 700;
          @include rem(padding, 0,35,0,0);
        }
      }

      &.has-icon {
        @include rem(padding, 10,0,10,35);
      }

      &:last-child {
       // border-bottom: 1px solid palette(grey, light);
      }


      &.has-label {

        .content {
          text-align: right;
          margin-left: auto;


          @include to(lp) {
            font-size: 12px;
          }
        }
      }

      &:not(.has-label) {

        .content {
          width: 100%;
        }
      }
    }
  }