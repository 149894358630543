//
// Grid Mixins
// --------------------------------------------------
// TODO: Need to refactor these spacing mixins

$debug-grid: false !default;

@mixin grid-background {
  @if $debug-grid == true {
    @include background-grid;
  }
}

// Left/right margin
@mixin add-margin-left($margin: $horz-spacing-unit){
  @include rem(margin-left, $margin  * $horz-spacing-unit-modifier-mobile);

  @include from(lt){
    @include rem(margin-left, $margin  * $horz-spacing-unit-modifier-tab);
  }

  @include from(d) {
    @include rem(margin-left, $margin);
  }
}

@mixin add-margin-right($margin: $horz-spacing-unit){
  @include rem(margin-right, $margin * $horz-spacing-unit-modifier-mobile);

  @include from(lt){
    @include rem(margin-right, $margin  * $horz-spacing-unit-modifier-tab);
  }

  @include from(d) {
    @include rem(margin-right, $margin);
  }
}

@mixin add-margin($margin: $horz-spacing-unit) {
  @include add-margin-right($margin);
  @include add-margin-left($margin);
}


// Left/right padding
@mixin add-padding-left($padding: $horz-spacing-unit){
  @include rem(padding-left, $padding  * $horz-spacing-unit-modifier-mobile);

  @include from(lt){
    @include rem(padding-left, $padding  * $horz-spacing-unit-modifier-tab);
  }

  @include from(d) {
    @include rem(padding-left, $padding);
  }
}

@mixin add-padding-right($padding: $horz-spacing-unit){
  @include rem(padding-right, $padding * $horz-spacing-unit-modifier-mobile);

  @include from(lt){
    @include rem(padding-right, $padding  * $horz-spacing-unit-modifier-tab);
  }

  @include from(d) {
    @include rem(padding-right, $padding);
  }
}

@mixin add-padding($padding: $horz-spacing-unit) {
  @include add-padding-right($padding);
  @include add-padding-left($padding);
}

@mixin add-padding-no-desk($padding: $horz-spacing-unit) {
  @include add-padding-right($padding);
  @include add-padding-left($padding);

  @include from(d) {
    @include rem(padding-left, 0);
    @include rem(padding-right, 0);
  }
}

%add-padding {
  @include add-padding;
}

@mixin content-vertical-spacing-top($type:'padding',$vertical-spacing:$vert-spacing-unit) {
  //setup padding variables if one was provided

  //setup units
  $vert-spacing-unit-desk  :$vertical-spacing;
  $vert-spacing-unit-tab   :$vertical-spacing * $vert-spacing-unit-modifier-tab;
  $vert-spacing-unit-mobile:$vertical-spacing * $vert-spacing-unit-modifier-mobile;


  #{$type}-top: rem-calc($vert-spacing-unit-mobile);

  @include from(pt) {
    #{$type}-top: rem-calc($vert-spacing-unit-tab);
  }

  @include from(d) {
    #{$type}-top: rem-calc($vert-spacing-unit-desk);
  }
}

@mixin content-vertical-spacing-bottom($type:'padding',$vertical-spacing:$vert-spacing-unit) {
  //setup padding variables if one was provided

  //setup units
  $vert-spacing-unit-desk  :$vertical-spacing;
  $vert-spacing-unit-tab   :$vertical-spacing * $vert-spacing-unit-modifier-tab;
  $vert-spacing-unit-mobile:$vertical-spacing * $vert-spacing-unit-modifier-mobile;


  #{$type}-bottom: rem-calc($vert-spacing-unit-mobile);

  @include from(pt) {
    #{$type}-bottom: rem-calc($vert-spacing-unit-tab);
  }

  @include from(d) {
    #{$type}-bottom: rem-calc($vert-spacing-unit-desk);
  }
}

@mixin content-vertical-spacing($type:'padding',$vertical-spacing:$vert-spacing-unit) {
  //setup padding variables if one was provided

  //setup units
  @include content-vertical-spacing-top($type,$vertical-spacing);
  @include content-vertical-spacing-bottom($type,$vertical-spacing);
}

@mixin remove-content-vertical-spacing-top($vertical-spacing:$vert-spacing-unit) {
  //setup padding variables if one was provided

  $vertical-spacing:$vertical-spacing * -1;

  @include content-vertical-spacing-top('margin',$vertical-spacing);

  padding-top: rem-calc(30);
  @include from(pt) {
    padding-top: rem-calc(40);
  }
}




%section-vertical-margin,
%vm{
  @include content-vertical-spacing('margin');
}

%content-vertical-margin,
%cvm{
  @include content-vertical-spacing('margin');
}
%content-vertical-padding,
%cvp{
  @include content-vertical-spacing();
}

%section-vertical-padding,
%vp{
  @include content-vertical-spacing();
}
// %section-margin-bottom,
// %mb{
//   @include section-margin-bottom();
// }
// %section-margin-top,
// %mt{
//   @include section-margin-bottom();
// }
@mixin vertical-align{
  position: relative;
  top: 50%;
  @include transform(translateY(-50%));
}
%vertical-align{
  @include vertical-align;
}
.cf{
  @include clearfix;
}
%cf{
  @include clearfix;
}
%pcf{
  @include pie-clearfix;
}
%l-constrained {
  margin-left: auto;
  margin-right: auto;
  //@include background-grid();
  min-width: 320px;
  @include rem(max-width,1250);
  // @include to(pp){
  //   max-width:rbp(pp);
  // }
  // @include from(lp){
  //    max-width:lbp(lp);
  // }
  // @include from(pt){
  //    max-width:bp(3);
  // }
  // @include from(lt){
  //    max-width:lbp(lt);
  // }
  // @include from(d){
  //    max-width:lbp(d);
  // }
  // @include from(ld){
  //    //max-width:lbp(ld);
  // }
  // @include from(vld){
  //    //max-width:lbp(vld);
  // }
}
